import React from 'react'
import { PostCard } from "./PostCard"

const PostCardFeed = ({ data }) => {
  const sanityPosts = data.allSanityPost.edges
  const authorsRootSlug = data.allSanitySiteSettings.edges[0].node.authorSlug

    return (
      <section className="post-feed">
        {sanityPosts!= null && sanityPosts.map(({ node }) => (
            <PostCard key={node.id} post={node} authorsRootSlug={authorsRootSlug} />
        ))}
      </section>
    )
}

export default PostCardFeed