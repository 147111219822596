import React from 'react'
import { getAuthorUrl, getCategoryUrl, getSlugFromRedirectValidatingDocument, getUrlFromReference } from "./urlProvider"
import { Link } from 'gatsby'

export const generateHtmlTagFromLink = (externalOrInternalURL, anchor, baseCssClass, additionalStyle) => { 
  const url = externalOrInternalURL.url
  const { isBlank, targetValue, relationship } = getTargetAndRelValues(externalOrInternalURL.linkProperties)

  if (url.match(/^\s?http(s?)/gi)) {
      return <a className={baseCssClass} href={url} style={additionalStyle} target={targetValue} rel={relationship}>{anchor}</a>
  } else {
      // Since the links are available on all pages they should toute from the root of the website
      const urlFromWebRoot = url.startsWith("/") ? url : "/" + url
      return generateInternalLink(anchor, urlFromWebRoot, isBlank, targetValue, baseCssClass, additionalStyle, relationship)
  }
}

export const generateHtmlTagFromReference = (pageReference, anchor, baseCssClass, additionalStyle, siteSettings) => {
  const url = getUrlFromReference(pageReference, siteSettings)
  const { isBlank, targetValue, relationship } = getTargetAndRelValues(pageReference.linkProperties)

  return generateInternalLink(anchor, url, isBlank, targetValue, baseCssClass, additionalStyle, relationship)
}

const getTargetAndRelValues = (linkProperties) => {
  if (linkProperties == null) {
    const isBlank = false
    const targetValue = null
    const relationship = ""
    return { isBlank, targetValue, relationship }
  } else {

    const isBlank = linkProperties.blank
    const targetValue = linkProperties.blank ? "_blank" : null
    const noReferrerFlag = linkProperties.blank ? "noopener noreferrer" : "";
    const noFollowFlag = linkProperties.nofollow ? "nofollow" : "";
    const relationship = `${noReferrerFlag} ${noFollowFlag}`.trim()

    return { isBlank, targetValue, relationship };
  }
}

const generateInternalLink = (anchor, url, isBlank, targetValue, baseCssClass, additionalStyle, relationship) => {
  if (isBlank) {
    return <a className={baseCssClass} href={url} style={additionalStyle} target={targetValue} rel={relationship}>{anchor}</a>
  } else {
    return <Link className={baseCssClass} to={url} style={additionalStyle} rel={relationship}>{anchor}</Link>
  }
}