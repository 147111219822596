import React from 'react'
import PropTypes from 'prop-types'
import { generateHtmlTagFromLink, generateHtmlTagFromReference } from "../../utils/urlFromSanity"

/**
* Navigation component
*
* The Navigation component takes an array of your Sanity
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/
const Navigation = ({ data, siteSettings, navClass, style }) => (
    <>
        {data.map((navItem, i) => {
            if (navItem.pageReference != null) {
                return (
                    <React.Fragment key={i}>
                        {generateHtmlTagFromReference(navItem.pageReference, navItem.label, navClass, style, siteSettings)}
                    </React.Fragment>
                )
            } else if (navItem.link != null && navItem.link.url != null) {
                return (
                    <React.Fragment key={i}>
                        {generateHtmlTagFromLink(navItem.link, navItem.label, navClass, style)}
                    </React.Fragment>
                )
            } else {
                // should never happen
            }
        })}
    </>
)

Navigation.defaultProps = {
    navClass: `site-nav-item`,
}

Navigation.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string,
            reference: PropTypes.object,
        }).isRequired,
    ).isRequired,
    navClass: PropTypes.string,
}

export default Navigation
