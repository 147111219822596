import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Pagination = ({ pageContext }) => {
    const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages, 
        paginationPreviousText, paginationNextText, paginationPageText, paginationOfText } = pageContext

    return (
        <nav className="pagination" role="navigation">
            <div>
                {previousPagePath && (
                    <Link to={previousPagePath == '/' ? '/' : `${previousPagePath}/`} rel="prev"> {/* Adding / at the end avoid 301 redirection */} 
                        {paginationPreviousText}
                    </Link>
                )}
            </div>
            {numberOfPages > 1 && <div className="pagination-location">{paginationPageText} {humanPageNumber} {paginationOfText} {numberOfPages}</div>}
            <div>
                {nextPagePath && (
                    <Link to={`${nextPagePath}/`} rel="next"> {/* Adding / at the end avoid 301 redirection */}
                        {paginationNextText}
                    </Link>
                )}
            </div>
        </nav>
    )
}

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired,
}

export default Pagination
