module.exports = {
    siteUrl: `https://www.marktecher.com`, // IMPORTANT! CHANGE ME!!! Site domain. Do not include a trailing slash!

    postsPerPage: 1, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Welcome to My Blog`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Welcome to my new blog. Click to find out about the topics that I write about...`, // This allows an alternative site description for meta data for pages.

    // NOTE: Share width and heights are used as recommended image sizes because they are aligned with the min image size requirements on the Sanity console
    shareImageWidth: 1200, // Default share image width that should work good for all major social media webistes
    shareImageHeight: 630, // Default share image height that should work good for all major social media webistes

    shareSquareImageSize: 600, // Width and height of the share images for squares. Companies logo and Authors' photos will use this sizes.

    shortTitle: `My Blog`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `static/images/logo.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest
}