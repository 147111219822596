import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Image from "gatsby-plugin-sanity-image"
import { getAuthorUrl, getSlugFromRedirectValidatingDocument } from "../../utils/urlProvider"

export const PostCard = ({ post, authorsRootSlug }) => {
    const url = `/${getSlugFromRedirectValidatingDocument(post).current}/`

    return (
        <div className="post-card">
            <Link to={url} className="post-card">
                <header className="post-card-header">
                    {/*{post.feature_image &&
                    <div className="post-card-image" style={{
                            backgroundImage: `url(${post.feature_image})` ,
                        }}></div>*/}
                    <Image
                        // pass asset, hotspot, and crop fields
                        {...post.mainImage}
                        // tell Sanity how large to make the image (does not set any CSS)
                        width={600}
                        alt={post.mainImage.alt}
                        // style it how you want it
                        style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "cover",
                            margin: "0 0 10px 0"
                        }}
                    />
                    {post.categories && post.categories.length != 0 && <div className="post-card-tags"> <span>{post.categories[0].name}</span></div>}
                    {/*{post.featured && <span>Featured</span>}*/}
                    <h2 className="post-card-title">{post.title}</h2>
                </header>
                <section className="post-card-excerpt">{post.excerpt}</section>
            </Link>
            {post.author &&
                <footer className="post-card-footer">
                    <div className="post-card-footer-left">
                        <div className="post-card-avatar">
                            {post.author.image ?
                                <Image className="author-profile-image" {...post.author.image} alt={post.author.name}/> :
                                <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.author.name}/>
                            }
                        </div>
                        <Link to={getAuthorUrl(authorsRootSlug, getSlugFromRedirectValidatingDocument(post.author))} className="post-card">
                            <span>{ post.author.name }</span>
                        </Link>
                    </div>
                    {/*<div className="post-card-footer-right">
                        <div>{readingTime}</div>
                    </div>*/}
                </footer>
            }
        </div>
    )
}
