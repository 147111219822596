import React from 'react'
import { Link } from 'gatsby'
import Image from "gatsby-plugin-sanity-image"
import { getCategoryUrl, getSlugFromRedirectValidatingDocument } from "../../utils/urlProvider"
import blockToPlainText from "../../utils/blockToPlainText"

const PostCardCategory = ({ category, sanitySiteSettings }) => {

    const categoriesRootSlug = getSlugFromRedirectValidatingDocument(sanitySiteSettings.tagsPage)
    const slug = getSlugFromRedirectValidatingDocument(category)
    const url = getCategoryUrl(categoriesRootSlug, slug, sanitySiteSettings.useCategoriesRoot)
    const categoryPlainText = category._rawDescription != null ? blockToPlainText(category._rawDescription) : ''

    return (
        <Link to={url} className="post-card">
            <header className="post-card-header">
                 {/*{post.feature_image &&
                   <div className="post-card-image" style={{
                        backgroundImage: `url(${post.feature_image})` ,
                    }}></div>*/}
                <Image
                    // pass asset, hotspot, and crop fields
                    {...category.image}
                    // tell Sanity how large to make the image (does not set any CSS)
                    width={200}
                    alt={category.image.alt}
                    // style it how you want it
                    style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                        margin: "0 0 10px 0"
                    }}
                />
                <h2 className="post-card-title">{category.name}</h2>
            </header>
            <section className="post-card-excerpt">{getShortenedText(categoryPlainText, 160)}</section>
        </Link>
    )
}

export default PostCardCategory

const getShortenedText = (text, maxLength) => {
    //trim the string to the maximum length
    let trimmedString = text.substring(0, maxLength + 1);
    
    //re-trim if we are in the middle of a word
    return trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
}
