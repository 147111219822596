const getUrlFromReference = function(pageReference, siteSettings) {
  const slug = getSlugFromRedirectValidatingDocument(pageReference.reference)

  switch (pageReference.reference._type) {
    case 'author': return getAuthorUrl(siteSettings.authorSlug, slug)
    case 'category': {
      // TODO: beautify in modernblogging impl
      const categoriesRootSlug = getSlugFromRedirectValidatingDocument(siteSettings.tagsPage)
      return getCategoryUrl(categoriesRootSlug, slug, siteSettings.useCategoriesRoot)
    }
    case 'post':
    case 'page':
    default: return `/${slug.current}/`
  }
}

const getAuthorUrl = function(authorsRootSlug, authorSlug) {
  return isAuthorRootSlugDefined(authorsRootSlug) ? `/${authorsRootSlug.current}/${authorSlug.current}/` : `/${authorSlug.current}/`
}

const getCategoryUrl = (categoriesRootSlug, categorySlug, useCategoriesRoot) => {
  return useCategoriesRoot ? `/${categoriesRootSlug.current}/${categorySlug.current}/` : `/${categorySlug.current}/`
}

const getSlugFromRedirectValidatingDocument = (node) => {
  if (node.slugWithRedirectValidation == null) {
    return { current : 'undefined' }
  }
  return node.slugWithRedirectValidation.slug
}

const getAuthorRootSlugValue = function(authorsRootSlug) {
  return isAuthorRootSlugDefined(authorsRootSlug) ? authorsRootSlug.current : ''
}

const getCategoryRootSlugValue = (categoriesRootSlug, useCategoriesRoot) => {
  return useCategoriesRoot ? categoriesRootSlug.current : ''
}

const isAuthorRootSlugDefined = (authorsRootSlug) => {
  return authorsRootSlug != null && authorsRootSlug.current 
}

module.exports = { getUrlFromReference, getAuthorUrl, getCategoryUrl, 
  getSlugFromRedirectValidatingDocument, getSlugFromRedirectValidatingDocument, 
  getAuthorRootSlugValue, getCategoryRootSlugValue }