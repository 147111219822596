
const sanityClient = require('@sanity/client')

export const MySanityClient = sanityClient({
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: 'production',
  apiVersion: '2021-08-31',
  // If your dataset is private you need to add a read token.
  // You can mint one at https://manage.sanity.io,
  useCdn: true,
})